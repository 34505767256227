import React from "react";
import { Col, Container, Row } from "reactstrap";

export type PropertyListType = {
    PropertyType:string,
    PropertyAddress:string,
    PropertyImage:string,
    PropertyPrice:string,
    PropertyMarketPrice:string,
    AuctionDate:string,
    PropertyBuiltUp:string
}
const PropertyList = (props:{propertyList: PropertyListType[]}) => {
    const { propertyList } = props;
    return (
        <div>
            <Container className="pt-4">
                <Row>
                    {propertyList.map((x, index) => {
                        return (
                            <Col key={index} md={12} xxl={3} className="pt-4">
                                <div style={{ minHeight:'700px', borderRadius:'20px', boxShadow:'0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.12)'}}>
                                    <img alt={'img-' + index} src={x.PropertyImage} width={'100%'} height={'250px'} style={{borderTopLeftRadius:'20px', borderTopRightRadius:'20px'}} />
                                    <Container className="pt-4">
                                        <Row>
                                            <Col xs={12}>
                                                <h6>{x.PropertyType}</h6>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <span>
                                                    <p style={{fontSize:'13px', fontWeight:'200', marginBottom:0}}>Address</p>
                                                    <p>{x.PropertyAddress}</p>
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <span>
                                                    <p style={{fontSize:'13px', fontWeight:'200', marginBottom:0}}>Price</p>
                                                    <p>{x.PropertyPrice}</p>
                                                </span>
                                            </Col>
                                        </Row>
                                        {x.PropertyMarketPrice !== '' && 
                                            <Row>
                                                <Col xs={12}>
                                                    <span style={{color:'red'}}>
                                                        <p style={{fontSize:'13px', fontWeight:'200', marginBottom:0}}>Market Price</p>
                                                        <p style={{textDecoration:'line-through'}}>{x.PropertyMarketPrice}</p>
                                                    </span>
                                                </Col>
                                            </Row>
                                        }
                                        <Row>
                                            <Col xs={12}>
                                                <span>
                                                    <p style={{fontSize:'13px', fontWeight:'200', marginBottom:0}}>Auction Date</p>
                                                    <p>{x.AuctionDate}</p>
                                                </span>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12}>
                                                <span>
                                                    <p style={{fontSize:'13px', fontWeight:'200', marginBottom:0}}>Built up</p>
                                                    <p>{x.PropertyBuiltUp}</p>
                                                </span>
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </div>
    )
}

export default PropertyList;