import React from "react";
import { Container, Row, Col } from "reactstrap";

type NavigationTypeData = {
    NavTitle:string,
    NavPath:string
}

type NavigationProps = {
    isMobile:boolean;
}
const Navigation = (props : NavigationProps) => {
    const NavigationData: NavigationTypeData[] = [
        {NavTitle:'Home', NavPath:'/'},
        {NavTitle:'About Us', NavPath:'/'},
        {NavTitle:'Demo', NavPath:'/'},
        {NavTitle:'Guide', NavPath:'/'},
        {NavTitle:'Contact Us', NavPath:'/'}
    ]
    const { isMobile } = props;
    return (
        <Container style={{height:'100%'}}>
            <Row style={{height:isMobile ? 'unset' : '100%', width:isMobile ? 'unset' : '100%', justifyContent:'right'}}>
                {NavigationData.map((x, index) => {
                    return (
                        <Col key={index} xs={isMobile ? '12' : 'auto'} className="ms-auto" style={{textAlign:isMobile ? 'center' : 'right', padding: '20px 0px', fontSize:'20px', fontWeight:'500'}}>
                            {x.NavTitle}
                        </Col>
                    )
                })}
                <Col key={'login'} xs={isMobile ? '12' : 'auto'} className="ms-auto" style={{textAlign:isMobile ? 'center' : 'right', padding:'20px 0px', fontSize:'20px', fontWeight:'500', display:isMobile? 'flex' : 'unset', justifyContent:isMobile ? 'center' :'unset'}}>
                    <div style={{backgroundColor:'#fbd303', width:isMobile ? '250px' : 'unset', padding:'10px 20px', height:'50px', marginTop:'-10px', borderRadius:'5px 15px', borderBottom:'3px solid gray', borderRight:'3px solid gray'}}>
                        Login / Register
                    </div>
                </Col>
            </Row>
        </Container>
    )
}

export default Navigation