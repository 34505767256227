import { atom, useRecoilState, useRecoilValue } from "recoil";
import { ViewMode } from "../commons/enum";

export type generalStateType = {
    viewMode: ViewMode
}

const defaultGeneralStateValue: generalStateType = {
    viewMode : ViewMode.XXLarge
}

export const GeneralState = atom({
  key: "generalState",
  default: defaultGeneralStateValue,
});

// export const SetViewModeState = (viewMode:ViewMode) => {
//     const [generalStateData, setGeneralStateData] = useRecoilState(GeneralState);

//     setGeneralStateData({...generalStateData, viewMode: viewMode})
// }

// export const GetViewModeState = () => {
//     const generalStateData = useRecoilValue(GeneralState);

//     return generalStateData.viewMode;
// }
