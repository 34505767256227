import React from "react";
import { Col, Container, Row } from "reactstrap";

type AuctionDataType = {
    AuctionTitle:string,
    AuctionType:string,
    AuctionFrom:string,
    AuctionTo:string,
    AuctionTime:string,
    AuctionVenue:string,
}

const AuctionData:AuctionDataType[] = [
    {
        AuctionTitle:'HONG LEONG BANK BERHAD & HONG LEONG ISLAMIC BANK BERHAD',
        AuctionType:'E-LELONG MAHKAMAH TINGGI MALAYA',
        AuctionFrom:'20 May 2024 (Monday)',
        AuctionTo:'30 Jun 2024 (Sunday)',
        AuctionTime:'9.00 A.M. & 11.00 A.M.',
        AuctionVenue:'Secara elektronik di Laman Web e-Lelong, Pusat Operasi e-Lelong, Kompleks Mahkamah Kuantan, Pahang'
    },
    {
        AuctionTitle:' LEMBAGA PEMBIAYAAN PERUMAHAN SEKTOR AWAM',
        AuctionType:'FULLY ONLINE',
        AuctionFrom:'25 May 2024 (Saturday)',
        AuctionTo:'25 May 2024 (Saturday)',
        AuctionTime:'9.00 A.M.',
        AuctionVenue:'VIA ONLINE BIDDING AT www.leongauctioneer.com'
    },
    {
        AuctionTitle:'STANDARD CHARTERED BANK MALAYSIA BERHAD',
        AuctionType:'BID ONLINE - BID ONSITE',
        AuctionFrom:'27 May 2024 (Monday)',
        AuctionTo:'27 May 2024 (Monday)',
        AuctionTime:'11.00 A.M.',
        AuctionVenue:'Suite 1009, 10th Floor, Amcorp Tower, Amcorp Trade Centre No. 18, Jalan Persiaran Barat, 46050 Petaling Jaya, Selangor'
    },
    {
        AuctionTitle:'MALAYAN BANKING BERHAD',
        AuctionType:'FULLY ONLINE',
        AuctionFrom:'28 May 2024 (Tuesday)',
        AuctionTo:'28 May 2024 (Tuesday)',
        AuctionTime:'12.00 NOON',
        AuctionVenue:'VIA ONLINE BIDDING AT www.leongauctioneer.com '
    },
    {
        AuctionTitle:'PERBADANAN NASIONAL BERHAD',
        AuctionType:'BID ONLINE - BID ONSITE',
        AuctionFrom:'30 May 2024 (Thursday)',
        AuctionTo:'30 May 2024 (Thursday)',
        AuctionTime:'10.00 A.M.',
        AuctionVenue:'Suite 1009, 10th Floor, Amcorp Tower, Amcorp Trade Centre, No. 18, Jalan Persiaran Barat, 46050 Petaling Jaya, Selango'
    }
]
const Auction = () => {
    return (
        <div style={{paddingTop:'50px'}}>
            <h1 style={{textAlign:'center', textDecoration:'underline'}}>Upcoming Auction</h1>
            <Container style={{paddingTop:'30px'}}>
                <Row>
                    {AuctionData.map((x, index) => {
                        return (
                            <Col key={index} md={4} className="pt-3">
                                <Container style={{backgroundColor:'white', minHeight:'350px', width:'100%', borderRadius:'20px 10px', borderBottom:'5px solid black', borderRight:'5px solid black', borderTop:'2px solid black', borderLeft:'2px solid black'}}>
                                    <Row>
                                        <Col xs={12} style={{borderTopLeftRadius:'15px', borderTopRightRadius:'5px', padding:'10px 0px', backgroundColor:'maroon'}}>
                                            <h5 style={{textAlign:'center', color:'white'}}>{x.AuctionTitle}</h5>
                                        </Col>
                                        <Col xs={12} className="py-3">
                                            <Container>
                                                <Row>
                                                    <Col xs="3" style={{textAlign:'right'}}>
                                                        <h6>Auction Type</h6>
                                                    </Col>
                                                    :
                                                    <Col xs="8">
                                                        <h6>{x.AuctionType}</h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="3" style={{textAlign:'right'}}>
                                                        <h6>From</h6>
                                                    </Col>
                                                    :
                                                    <Col xs="8">
                                                        <h6>{x.AuctionFrom}</h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="3" style={{textAlign:'right'}}>
                                                        <h6>To</h6>
                                                    </Col>
                                                    :
                                                    <Col xs="8">
                                                        <h6>{x.AuctionTo}</h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="3" style={{textAlign:'right'}}>
                                                        <h6>Time</h6>
                                                    </Col>
                                                    :
                                                    <Col xs="8">
                                                        <h6>{x.AuctionTime}</h6>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs="3" style={{textAlign:'right'}}>
                                                        <h6>Venue</h6>
                                                    </Col>
                                                    :
                                                    <Col xs="8">
                                                        <h6 style={{overflowWrap:'break-word'}}>{x.AuctionVenue}</h6>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        )
                    })}
                </Row>
            </Container>
        </div>
    )
}

export default Auction;