import React, { useEffect } from "react";
import { GeneralState } from "../applicationStates/generalState";
import { useRecoilState } from "recoil";
import { getViewModeState } from "../commons/miscHelpers";

type Props = {
  children?: React.ReactNode;
};
const MasterPage = (props: Props) => {
    const [generalStateData, setGeneralStateData] = useRecoilState(GeneralState);
    useEffect(()=> {
        setGeneralStateData({...generalStateData, viewMode:getViewModeState(window.innerWidth)})
        window.onresize = () => {
            setGeneralStateData({...generalStateData, viewMode:getViewModeState(window.innerWidth)})
        }
    },[])

    return (
        <div className='poppins'>
            {props.children}
        </div>
    )
}

export default MasterPage;
