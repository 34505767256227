import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Customer from './Customer';
import 'bootstrap/dist/css/bootstrap.min.css';
import { RecoilRoot } from 'recoil';
import MasterPage from './pages/MasterPage';

const PageIndex = () => {
  return (
      <RecoilRoot>
        <MasterPage>
          <BrowserRouter>
            <Routes>
                <Route index element={<App />} />
                <Route path='customer' element={<Customer />} />
            </Routes>
          </BrowserRouter>
        </MasterPage>
      </RecoilRoot>
  )
}

export default PageIndex;

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(<PageIndex />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
