import React from "react";
import { Col, Container, Row } from "reactstrap";

const Footer = () => {
    return (
        <div>
            <Container className="pt-5 pb-5">
                <Row>
                    <Col lg={4} xs={12} className="pt-2">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <h5 style={{fontWeight:'600'}}>ABOUT leongauctioneer.com</h5>
                                </Col>
                                <Col xs={12} className="pt-3">
                                    <span>
                                        The firm known as Leong Auctioneer Agency in 1990,the firm provide professional auction services.Actively involved in auction sale of foreclosure properties,financial institutions,individual property,liquidator  also conducting private auction on movable & immoveable properties.<br/><br/>
                                        Later on the firm incorporated as Leong Auctioneer Sdn Bhd towards to a better future of the industry providing reliable & professional auction services.
                                    </span>
                                </Col>
                                <Col xs={12} className="pt-3">
                                    <span>
                                        <p style={{margin:0, fontWeight:'600'}}>Telephone</p>
                                        03-795 83 795 / 012-7193 395
                                    </span>
                                </Col>
                                <Col xs={12} className="pt-3">
                                    <span>
                                        <p style={{margin:0, fontWeight:'600'}}>Fax</p>
                                        03-795 78 795
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col lg={2} xs={12} className="pt-2">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <span>
                                        <h5 style={{fontWeight:'600'}}>Business Partner</h5>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col lg={2} xs={12} className="pt-2">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <span>
                                        <h5 style={{fontWeight:'600'}}>Auction Info</h5>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col lg={4} xs={12} className="pt-2">
                        <Container>
                            <Row>
                                <Col xs={12}>
                                    <span>
                                        <h5 style={{fontWeight:'600'}}>Site Map </h5>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Footer;