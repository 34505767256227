import React, { useEffect, useState } from "react";
import { Col, Container, Row } from 'reactstrap';
import Logo from '../../assets/auc.png'
import Navigation from "../navigation/Navigation";
import Menu from '../../assets/menu.png';
import Close from '../../assets/close.png';
import { ReactComponent as CircleSvg } from "../../assets/f.svg";

const Header = () => {
    const [showMenu, setShowMenu] = useState<boolean>(false);
    const [showStickyHeader, setShowStickyHeader] = useState<boolean>(false);

    const menuClick = () => {
        setShowMenu(!showMenu);
    }

    useEffect(()=> {
        setShowStickyHeader(window.scrollY > 52);
        window.onscroll = () => {
            setShowStickyHeader(window.scrollY > 52);
        }
    },[])

    return (
        <>
            <header style={{left: 0, right: 0, width:'100%', top: 0, zIndex: 9, position:showStickyHeader ? 'fixed' : 'absolute'}}>
                {showStickyHeader === false && 
                    <div style={{width:'100%', backgroundColor:'#001D38'}}>
                        <Container style={{padding:'12px 10px'}}>
                            <Row>
                            <Col xs={8}>
                                <p style={{color:'#fbd303', margin:'0', lineHeight:'28px', fontSize:'14px'}}>Welcome to Leong Auction</p>
                            </Col>
                            <Col xs={4} style={{textAlign:'right'}}>
                                <CircleSvg fill="white" width={'20px'} height={'15px'} style={{marginRight:'15px'}} />
                            </Col>
                            </Row>
                        </Container>
                    </div>
                }
                <div style={{width:'100%', height:'90px', margin:'auto', padding:'10px', backgroundColor:'white'}}>
                    <Container style={{padding:'0px 0px'}}>
                        <Row>
                        <Col lg={2} xs={6}>
                            <img alt="logo" src={Logo} width={'70px'} />
                        </Col>
                        <Col lg={10} className="d-none d-lg-block">
                            <Navigation isMobile={false} />
                        </Col>
                        <Col xs={6} className="d-lg-none" style={{textAlign:'right'}}>
                            <img alt="menu" src={showMenu ? Close : Menu} style={{paddingBottom:'20px', paddingTop:'20px', paddingRight:'15px', width:'50px', cursor:'pointer', transition:'2.5s'}} onClick={menuClick} />
                        </Col>
                        </Row>
                    </Container>
                </div>
            </header>
            <div style={{height:'100%', width:showMenu === true ? '320px' : '0px', backgroundColor:'white', position:'fixed', zIndex:10, transition:'0.5s'}}>
                {showMenu === true && 
                    <>
                        <div style={{width:'100%', height:'90px', padding:'10px', textAlign:'right'}}>
                            <img alt="close" src={Close} style={{paddingBottom:'20px', paddingTop:'20px', paddingRight:'25px', width:'60px', cursor:'pointer', transition:'2.5s'}} onClick={menuClick} />
                        </div>
                        <Navigation isMobile={true} />
                    </>
                }
            </div>
      </>
    )
}

export default Header;