import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { GeneralState } from "../../applicationStates/generalState";
import { Col, Container, Row } from "reactstrap";

const PropertySearchSlider = () => {
    const generalStateData = useRecoilValue(GeneralState);
    const [fontSize, setFontSize] = useState<string>('');
    const [lineHeight, setLineHeight] = useState<string>('');
    
    useEffect(() => {
        const viewMode = generalStateData.viewMode;
        setFontSize(viewMode > 3 ? '70px' : viewMode > 0  ? '50px' :'30px');
        setLineHeight(viewMode > 3 ? '70px' : viewMode > 0  ? '50px' :'45px');
    }, [generalStateData.viewMode])

    return (
        <>
        <Container>
            <Row>
                <Col xs={12}>
                    <h3 style={{color:'white', fontSize:fontSize, lineHeight:lineHeight, fontWeight:'600', textAlign:'center'}}>Find Properties Auction </h3>
                </Col>
                <Col xs={12} className="pt-2">
                    <Container className="px-4 py-2" style={{background:'rgba(0, 28, 56, 0.6)', borderRadius:'10px'}}>
                        <Row>
                            <Col lg={8} className="pt-3">
                                <p style={{color:'#fbd303'}}>Address</p>
                                <input type={'text'} style={{width:'100%', height:'40px'}} />
                            </Col>
                            <Col lg={4} className="pt-3">
                                <p style={{color:'#fbd303'}}>Auction Date</p>
                                <input type={'date'} style={{width:'100%', height:'40px'}} />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={3} className="pt-3">
                                <p style={{color:'#fbd303'}}>Property Type</p>
                                <select id="cars" name="cars" style={{width:'100%', height:'40px'}}>
                                    <option value="19">Apartment / Condo / SOHO</option>
                                    <option value="80">Factory</option>
                                    <option value="20">Flat</option>
                                    <option value="81">Hotel / Resort / Clubhouse</option>
                                    <option value="78">Land/Vacant Land/Detach Land/Agricultural Land/Residential Land</option>
                                    <option value="140">Low Cost Flat</option>
                                    <option value="18">Semi D / Bungalow / Villa</option>
                                    <option value="21">Shop Office / Retail Space / Office</option>
                                    <option value="17">Terrace / Link / Town House</option>
                                    <option value="82">Town House</option>
                                </select>
                            </Col>
                            <Col lg={3} className="pt-3">
                                <p style={{color:'#fbd303'}}>State</p>
                                <select id="cars" name="cars" style={{width:'100%', height:'40px'}}>
                                    <option value="1">Johor</option>
                                    <option value="2">Kedah</option>
                                    <option value="3">Kelantan</option>
                                    <option value="14">Kuala Lumpur</option>
                                    <option value="4">Melaka</option>
                                    <option value="5">Negeri Sembilan</option>
                                    <option value="6">Pahang</option>
                                    <option value="8">Perak</option>
                                    <option value="9">Perlis</option>
                                    <option value="7">Pulau Pinang</option>
                                    <option value="115">Sabah</option>
                                    <option value="117">Sarawak</option>
                                    <option value="10">Selangor</option>
                                    <option value="11">Terengganu</option>
                                    <option value="116">Wilayah Persekutuan Labuan </option>
                                    <option value="16">Wilayah Persekutuan Putrajaya</option>
                                </select>
                            </Col>
                            <Col lg={3} className="pt-3">
                                <p style={{color:'#fbd303'}}>Listing Type</p>
                                <select id="cars" name="cars" style={{width:'100%', height:'40px'}}>
                                    <option value="22">Active Listing</option>
                                    <option value="23">Expired Listing</option>
                                </select>
                            </Col>
                            <Col lg={3}>
                                <div style={{backgroundColor:'#fbd303', width:'fit-content', padding:'10px 20px', height:'50px', marginTop:'30px', borderRadius:'5px 15px', borderBottom:'3px solid gray', borderRight:'3px solid gray'}}>
                                    Find Auction Property
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        </Container>
        </>
    )
}

export default PropertySearchSlider;