import React from 'react';
// import logo from './logo.svg';
import './App.css';
// import * as stylex from '@stylexjs/stylex';
import banner from './assets/banner.png'
import Header from './components/header/header';
import PropertySearchSlider from './components/slider/PropertySearchSlider';
import { useRecoilValue } from 'recoil';
import { GeneralState } from './applicationStates/generalState';
import Auction from './components/auction/Auction';
import Property from './components/property/Property';
import Footer from './components/footer/Footer';


const App = () => {
  const generalStateData = useRecoilValue(GeneralState);
  return (
    <>
      <Header />
      <div>
        <div style={{backgroundColor:'#001D38', left: 0, right: 0, width:'100%', height:'900px', opacity:0.5, zIndex:0, position:'absolute'}}>
        </div>
        <div style={{height:'900px', backgroundSize:'cover', backgroundRepeat:'no-repeat', backgroundPosition:'center center', backgroundImage:'Url('+ banner+ ')'}}>
        </div>
      </div>
      <div style={{top:'0px', left: 0, right: 0, zIndex:1, position:'absolute'}}>
        {generalStateData.viewMode < 3 && 
          <div style={{height:'80px'}}>&nbsp</div>
        } 
        <div style={{height:generalStateData.viewMode < 3 ? '820px' : '900px', width:'100%' , display:'flex', justifyContent:'center', alignItems:'center'}}>
          <PropertySearchSlider />
        </div>
        <Auction />
        <Property />
        <Footer />
      </div>
    </>
  );
}

export default App;

// const styles = stylex.create({
//   header: {
//     width: '100%',
//     backgroundColor: '#fbd303',
//     height: '52px'
//   },
// });
