import { ViewMode } from "./enum";

export const getViewModeState = (screenSize:number) => {
    if(screenSize >= 1400){
        return ViewMode.XXLarge;
    }
    else if(screenSize >= 1200){
        return ViewMode.XLarge;
    }
    else if(screenSize >= 992){
        return ViewMode.Large;
    }
    else if(screenSize >= 768){
        return ViewMode.Medium;
    }
    else if(screenSize >= 576){
        return ViewMode.Small;
    }
    else{
        return ViewMode.XSmall;
    }
}