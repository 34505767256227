import React from "react";

// type customer = {
//     id:string;
//     name:string;
//     email:string;
// }
const Customer = () => {
    // const [loading,setLoading] = useState<boolean>(false);
    // const [customerData,setCustomerData] = useState<customer[]>([]);
    // useEffect(()=> {
    //     setLoading(true)
    //     fetch('http://localhost:4943/Customer',{
    //         method: 'GET'
    //     })
    //     .then((response) => response.json())
    //     .then((data:customer[]) => {
    //         setLoading(false);
    //         setCustomerData(data);
    //     })
    // },[])
    return (
        <>
            <h1>Customer</h1>
            {/* {loading ? 
                <p>Loading...</p> : 
                customerData.map((x, index) => {
                    return (<h1 key={index}>{x.name}</h1>)
                })      
            }                       */}
        </>
    )
}

export default Customer;