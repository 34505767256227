import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import PropertyList, { PropertyListType } from "./PropertyList";
import Property1 from '../../assets/property.jpg';
import Property2 from '../../assets/property2.jpg';
import Property3 from '../../assets/property3.jpg';
import Property4 from '../../assets/property4.jpg';
import Property5 from '../../assets/property5.jpg';
import Property6 from '../../assets/property6.jpg';
import Property7 from '../../assets/property7.jpeg';

enum PropertyTypeEnum {
    Recomemded,
    Below,
    Popular
}
const Property = () => {
    const RecomemdedProperty : PropertyListType[] = [{
        PropertyAddress:'No. SA2-13A-12, Vista Alam, No. 3, Presint 4.5, Jalan Ikhtisas 14/1, 40000 Shah Alam, Selangor',
        PropertyBuiltUp:'125.51 square metres / 1,351 square feet',
        AuctionDate:'28 May 2024 (Tuesday)',
        PropertyMarketPrice:'RM 670,000.00 ',
        PropertyPrice:'RM 513,000.00',
        PropertyType:'SERVICE RESIDENCE (BUMI LOT)',
        PropertyImage:Property1
    },
    {
        PropertyAddress:'Unit No. B-25-01, Residensi Estetika, Jalan Dagang SB, The Mines Resort City, 43300 Seri Kembangan, Selangor',
        PropertyBuiltUp:'80.303 square metres / 864 square feet',
        AuctionDate:'28 May 2024 (Tuesday)',
        PropertyMarketPrice:'RM 450,000.00',
        PropertyPrice:'RM 324,000.00',
        PropertyType:'SERVICE APARTMENT ',
        PropertyImage:Property2
    },
    {
        PropertyAddress:'No. 29, Jalan Eco Majestic 2/2C, Eco Majestic, 43500 Semenyih, Selangor',
        PropertyBuiltUp:'Land Area - 398 square metres / 4,284 square feet',
        AuctionDate:'28 May 2024 (Tuesday)',
        PropertyMarketPrice:'',
        PropertyPrice:'RM 1,260,000.00',
        PropertyType:'DOUBLE-STOREY STRATIFIED CORNER SEMI-DETACHED HOUSE ',
        PropertyImage:Property3
    },
    {
        PropertyAddress:'Unit No. D-15-1, Delta Residenz, Pacific Towers, Jalan 13/6, Seksyen 13, 46200 Petaling Jaya, Selangor',
        PropertyBuiltUp:'57.79 square metres / 622 square feet',
        AuctionDate:'28 May 2024 (Tuesday)',
        PropertyMarketPrice:'',
        PropertyPrice:'RM 435,000.00',
        PropertyType:'ONE-BEDROOM SERVICED APARTMENT',
        PropertyImage:Property4
    }]

    const BelowMarketProperty : PropertyListType[] = [{
        PropertyAddress:'Unit No. D210, Blok D, Rumah Pangsa Kajang Utama, Jalan Seksyen 1/1, Taman Kajang Utama, 43000 Kajang, Selangor',
        PropertyBuiltUp:'589.5 square feet',
        AuctionDate:'28 May 2024 (Tuesday)',
        PropertyMarketPrice:'RM 128,000.00',
        PropertyPrice:'RM 92,000.00',
        PropertyType:'LOW COST FLAT ',
        PropertyImage:Property5
    },
    {
        PropertyAddress:'Unit No. B-25-01, Residensi Estetika, Jalan Dagang SB, The Mines Resort City, 43300 Seri Kembangan, Selangor',
        PropertyBuiltUp:'80.303 square metres / 864 square feet',
        AuctionDate:'28 May 2024 (Tuesday)',
        PropertyMarketPrice:'RM 450,000.00',
        PropertyPrice:'RM 324,000.00',
        PropertyType:'SERVICE APARTMENT ',
        PropertyImage:Property6
    }]

    const PopularProperty : PropertyListType[] = [{
        PropertyAddress:'No 24, Jalan Saujana Gemilang 6, Taman Saujana, 86000, Kluang, Johor',
        PropertyBuiltUp:'Land Area - 297.00 square metres / 3196.88 square feet',
        AuctionDate:'28 May 2024 (Tuesday)',
        PropertyMarketPrice:'',
        PropertyPrice:'RM 570,000.00',
        PropertyType:'DOUBLE STOREY LINK HOUSE ',
        PropertyImage:Property7
    }]

    const [displayProperty, setDisplayProperty] = useState<PropertyTypeEnum>(PropertyTypeEnum.Recomemded);
     const [propertyListData,setListPropertyData] = useState<PropertyListType[]>(RecomemdedProperty);

    useEffect(()=> {
        if(displayProperty === PropertyTypeEnum.Recomemded){
            setListPropertyData(RecomemdedProperty)
        }
        else if(displayProperty === PropertyTypeEnum.Below){
            setListPropertyData(BelowMarketProperty)
        }
        else if(displayProperty === PropertyTypeEnum.Popular){
            setListPropertyData(PopularProperty)
        }
        else{
            setListPropertyData([])
        }
    },[displayProperty])

    return (
        <div style={{paddingTop:'30px', marginTop:'30px', paddingBottom:'30px', backgroundColor:'#f8f9fa'}}>
            <h1 style={{textAlign:'center', textDecoration:'underline'}}>Property</h1>
            <Container className="pt-4" style={{display:'flex', justifyContent:'center', textAlign:'center'}}>
                <Row>
                    <Col>
                        <h6 style={{whiteSpace:'nowrap', cursor:'pointer', fontWeight:'300', height:'30px',  borderBottom:displayProperty === PropertyTypeEnum.Recomemded ? '2px solid #fbd303' : 'unset'}} onClick={() => {setDisplayProperty(PropertyTypeEnum.Recomemded)}}>Recomended</h6>
                    </Col>
                    <Col>
                        <h6 style={{whiteSpace:'nowrap', cursor:'pointer', fontWeight:'300', height:'30px',  borderBottom:displayProperty === PropertyTypeEnum.Below ? '2px solid #fbd303' : 'unset'}} onClick={() => {setDisplayProperty(PropertyTypeEnum.Below)}}>Below Market Price</h6>
                    </Col>
                    <Col>
                        <h6 style={{whiteSpace:'nowrap', cursor:'pointer', fontWeight:'300', height:'30px',  borderBottom:displayProperty === PropertyTypeEnum.Popular ? '2px solid #fbd303' : 'unset'}} onClick={() => {setDisplayProperty(PropertyTypeEnum.Popular)}}>Popular</h6>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}></Col>
                </Row>
            </Container>
            <PropertyList propertyList={propertyListData} />
        </div>
    )
}

export default Property;